import {
  DailyOverviewTreatmentsOptions,
  IDailyOverviewTreatmentsDateRange,
  Treatment,
  TreatmentsRates,
} from '@app/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const featureAdapter: EntityAdapter<Treatment> = createEntityAdapter<
  Treatment
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<Treatment> {
  isLoading?: boolean;
  error?: any;
  data: TreatmentsRates;
  datesRanges: IDailyOverviewTreatmentsDateRange[];
  options: DailyOverviewTreatmentsOptions;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  data: null,
  datesRanges: [],
  options: {
    show: {
      accommodations: {},
      treatments: {},
    },
    event: true,
    expand: true,
  },
});
