import { Action, createReducer, on } from '@ngrx/store';
import { get, merge } from 'lodash';

import { rangeFromPeriod } from '../../helpers/range-from-period';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRateRequest, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadRateSuccess, (state, { items }) => ({
    ...state,
    data: merge({}, state.data, items),
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadRateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.updateRoomBarredRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.updateRoomBarredSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.updateRoomBarredFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(
    fromActions.setPeriodDailyOverviewTreatment,
    (state, { startDate, endDate }) => ({
      ...state,
      datesRanges: rangeFromPeriod(startDate, endDate),
    }),
  ),
  on(fromActions.setOptionsEpxandRequest, (state, { expand }) => ({
    ...state,
    options: { ...state.options, expand },
  })),
  on(fromActions.setOptionsRequest, (state, { options }) => ({
    ...state,
    options: {
      ...state.options,
      ...options,
      show: {
        ...options.show,
        accommodations: options.show.accommodations,
        treatments: options.show.treatments,
      },
    },
  })),

  on(fromActions.resetState, () => fromState.initialState),

  on(fromActions.resetRateState, (state) => ({ ...state, data: null })),
);

export function dailyOverviewTreatmentsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
