import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import {
  TreatmentRatesAccommodationRequest,
  TreatmentSetRoomBarred,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class DailyOverviewTreatmentsService {
  constructor(private http: HttpClient) {}

  loadRatesAccommodation(data: TreatmentRatesAccommodationRequest) {
    const { date_from, date_to, propertyId, accommodationId, treatmentId } =
      data;
    return this.http.get(
      `treatments_rates/${date_from}/${date_to}/?accommodations[${+propertyId}][]=${+accommodationId}&property_id[]=${+propertyId}&treatments[]=${+treatmentId}`,
    );
  }

  updateRoomBarred(data: TreatmentSetRoomBarred) {
    return this.http.post(`treatments_rates/barredroom`, data);
  }
}
