import moment from 'moment';

export const rangeFromPeriod = (
  startDate: Date,
  endDate: Date,
): {
  id: string;
  day: string;
  month: string;
  weekday: string;
  weekdayId?: number;
}[] => {
  const range: Date[] = [];
  const currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(endDate, 'day')) {
    range.push(currentDate.toDate());
    currentDate.add('1', 'day');
  }

  return range.map((date) => {
    const momentDate = moment(date);
    return {
      id: moment(date).format('YYYY-MM-DD'),
      day: momentDate.format('D'),
      month: momentDate.format('MMM'),
      weekday: momentDate.format('ddd'),
      weekdayId: date.getDay(),
    };
  });
};
