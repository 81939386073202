import { createAction, props } from '@ngrx/store';

import {
  DailyOverviewTreatmentsOptions,
  TreatmentRatesAccommodationRequest,
  TreatmentSetRoomBarred,
  TreatmentsRates,
} from '../../models';

export const loadRateRequest = createAction(
  '[Daily Overview Treatments] Load Rate Request',
  props<{ data: TreatmentRatesAccommodationRequest }>(),
);

export const loadRateSuccess = createAction(
  '[Daily Overview Treatments] Load Rate Success',
  props<{
    items: TreatmentsRates;
    request: TreatmentRatesAccommodationRequest;
  }>(),
);

export const loadRateFailure = createAction(
  '[Daily Overview Treatments] Load Rate Failure',
  props<{ error: any }>(),
);

export const updateRoomBarredRequest = createAction(
  '[Daily Overview Treatments] Update RoomBarred Request',
  props<{ data: TreatmentSetRoomBarred }>(),
);

export const updateRoomBarredSuccess = createAction(
  '[Daily Overview Treatments] Update RoomBarred Success',
  props<{ data: TreatmentSetRoomBarred }>(),
);

export const updateRoomBarredFailure = createAction(
  '[Daily Overview Treatments] Update RoomBarred Failure',
  props<{ error: any }>(),
);
export const setPeriodRequest = createAction(
  '[Daily Overview Treatments] Set Period Request',
  props<{ propertiesIds: number[] }>(),
);

export const setOptionsRequest = createAction(
  '[Daily Overview Treatments] Set Options Request',
  props<{ options: DailyOverviewTreatmentsOptions }>(),
);

export const setOptionsEpxandRequest = createAction(
  '[Daily Overview Treatments] Set Expand Request',
  props<{ expand: boolean }>(),
);

export const setPeriodDailyOverviewTreatment = createAction(
  '[Daily Overview Treatments] Set Period Daily Overview Request',
  props<{
    startDate: Date;
    endDate: Date;
    propertiesIds: number[];
  }>(),
);

export const setPeriodDailyOverviewTreatmentSuccess = createAction(
  '[Daily Overview Treatments] Set Period Daily Overview Success',
  props<{
    startDate: Date;
    endDate: Date;
  }>(),
);

export const resetState = createAction(
  '[Daily Overview Treatments] Reset State',
);
export const resetRateState = createAction(
  '[Daily Overview Treatments] Reset Rate State',
);
