import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DailyOverviewTreatmentsStoreEffects } from './effects';
import { dailyOverviewTreatmentsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'dailyOverviewTreatments',
      dailyOverviewTreatmentsReducer,
    ),
    EffectsModule.forFeature([DailyOverviewTreatmentsStoreEffects]),
  ],
})
export class DailyOverviewTreatmentsStoreModule {}
