import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { DailyOverviewTreatmentsService } from '../../services/daily-overview-treatments.service';

import * as featureActions from './actions';

@Injectable()
export class DailyOverviewTreatmentsStoreEffects {
  loadRates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.loadRateRequest),
      concatMap(({ data }) =>
        this.dataService.loadRatesAccommodation(data).pipe(
          map((request: IResponseSuccess) => {
            return featureActions.loadRateSuccess({
              items: request.data,
              request: data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(featureActions.loadRateFailure({ error }));
          }),
        ),
      ),
    ),
  );

  setDailyOverviewPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.setPeriodDailyOverviewTreatment),
      map((payload) => {
        const { startDate, endDate } = payload;
        return featureActions.setPeriodDailyOverviewTreatmentSuccess({
          startDate,
          endDate,
        });
      }),
    ),
  );

  updateRoomBarred$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.updateRoomBarredRequest),
      concatMap(({ data }) =>
        this.dataService.updateRoomBarred(data).pipe(
          map((request: IResponseSuccess) => {
            return featureActions.updateRoomBarredSuccess({ data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(featureActions.updateRoomBarredFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private dataService: DailyOverviewTreatmentsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}
}
