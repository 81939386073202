import {
  DailyOverviewTreatmentsOptions,
  IDailyOverviewTreatmentsDateRange,
  Treatment,
  TreatmentsRates,
} from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getTreatmentsRate = (state: State): TreatmentsRates => state.data;

export const getOptions = (state: State): DailyOverviewTreatmentsOptions =>
  state.options;

export const getDatesRange = (
  state: State,
): IDailyOverviewTreatmentsDateRange[] => state.datesRanges;

export const selectDailyOverviewTreatmentState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('dailyOverviewTreatments');

export const selectDailyOverviewTreatmentsItems: (
  state: object,
) => Treatment[] = featureAdapter.getSelectors(
  selectDailyOverviewTreatmentState,
).selectAll;

export const selectDailyOverviewTreatmentsError: MemoizedSelector<
  object,
  any
> = createSelector(selectDailyOverviewTreatmentState, getError);

export const selectDailyOverviewTreatmentsIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectDailyOverviewTreatmentState, getIsLoading);

export const selectDailyOverviewTreatmentData: MemoizedSelector<
  object,
  TreatmentsRates
> = createSelector(selectDailyOverviewTreatmentState, getTreatmentsRate);

export const selectDailyOverviewTreatmentOptions: MemoizedSelector<
  object,
  DailyOverviewTreatmentsOptions
> = createSelector(selectDailyOverviewTreatmentState, getOptions);

export const selectDailyOverviewTreatmentDatesRange: MemoizedSelector<
  object,
  IDailyOverviewTreatmentsDateRange[]
> = createSelector(selectDailyOverviewTreatmentState, getDatesRange);
